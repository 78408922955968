export type Slot = {
  name: string;
  path: string;
  id: string | undefined;
  mapping: Sizes;
};

type Sizes = {
  desktop: googletag.GeneralSize;
  tablet: googletag.GeneralSize;
  mobile: googletag.GeneralSize;
};

const horizontalMobileSizes: googletag.MultiSize = [
  [320, 50],
  [320, 100],
  [300, 250],
];
const horizontalTabletSizes: googletag.MultiSize = [[728, 90]];
const horizontalDesktopSizes: googletag.MultiSize = [
  [728, 90],
  [970, 90],
];

const horizontalSplitViewMobileSizes: googletag.MultiSize = [
  [320, 50],
  [320, 100],
  [300, 250],
];

const horizontalSplitViewTabletSizes: googletag.MultiSize = [
  [320, 50],
  [320, 100],
  [300, 250],
];
const horizontalSplitViewDesktopSizes: googletag.MultiSize = [
  [320, 50],
  [320, 100],
  [300, 250],
];

//
// Google Ads Slots Mapping for Index page
//

const TOP_LEVEL = 'Chronogolf_US_EN';

export const IndexGoogleAdsSlot: Slot[] = [
  {
    name: 'index_leaderboard',
    path: `/22825980381/${TOP_LEVEL}/chrono_homepage_leaderboard`,
    id: 'div-gpt-ad-1664562663029-0',
    mapping: {
      desktop: horizontalDesktopSizes,
      tablet: horizontalTabletSizes,
      mobile: horizontalMobileSizes,
    },
  },
  {
    name: 'index_bottombanner',
    path: `/22825980381/${TOP_LEVEL}/chrono_homepage_bottombanner`,
    id: 'div-gpt-ad-1664565289292-0',
    mapping: {
      desktop: horizontalDesktopSizes,
      tablet: horizontalTabletSizes,
      mobile: horizontalMobileSizes,
    },
  },
  {
    name: 'index_mobileclubsgrid',
    path: `/22825980381/${TOP_LEVEL}/chrono_homepage_mobileclubsgrid`,
    id: 'div-gpt-ad-1672957118440-0',
    mapping: {
      desktop: [],
      tablet: [],
      mobile: horizontalMobileSizes,
    },
  },
];

//
// Google Ads Slots Mapping for Destinations page
//
export const destinationsGoogleAdsSlots: Slot[] = [
  {
    name: 'chrono_destinations_top_banner',
    path: `/22825980381/${TOP_LEVEL}/chrono_destinations_top_banner`,
    id: 'div-gpt-ad-1676498279120-top',
    mapping: {
      desktop: horizontalDesktopSizes,
      tablet: horizontalTabletSizes,
      mobile: horizontalMobileSizes,
    },
  },
  {
    name: 'chrono_destinations_middle_banner',
    path: `/22825980381/${TOP_LEVEL}/chrono_destinations_middle_banner`,
    id: 'div-gpt-ad-1676498279120-middle',
    mapping: {
      desktop: horizontalDesktopSizes,
      tablet: horizontalTabletSizes,
      mobile: horizontalMobileSizes,
    },
  },
  {
    name: 'chrono_destinations_bottom_banner',
    path: `/22825980381/${TOP_LEVEL}/chrono_destinations_bottom_banner`,
    id: 'div-gpt-ad-1676498279120-bottom',
    mapping: {
      desktop: horizontalDesktopSizes,
      tablet: horizontalTabletSizes,
      mobile: horizontalMobileSizes,
    },
  },
];

//
// Google Ads Slots Mapping for Search page
//
export const locationsGoogleAdsSlots: Slot[] = [
  {
    name: 'chrono_search_top_banner',
    path: `/22825980381/${TOP_LEVEL}/chrono_search_top_banner`,
    id: 'div-gpt-ad-1680614063606-top',
    mapping: {
      desktop: horizontalSplitViewDesktopSizes,
      tablet: horizontalSplitViewTabletSizes,
      mobile: horizontalSplitViewMobileSizes,
    },
  },
  {
    name: 'chrono_search_middle_banner',
    path: `/22825980381/${TOP_LEVEL}/chrono_search_middle_banner`,
    id: 'div-gpt-ad-1680614022014-middle',
    mapping: {
      desktop: horizontalSplitViewDesktopSizes,
      tablet: horizontalSplitViewTabletSizes,
      mobile: horizontalSplitViewMobileSizes,
    },
  },
  {
    name: 'chrono_search_bottom_banner',
    path: `/22825980381/${TOP_LEVEL}/chrono_search_bottom_banner`,
    id: 'div-gpt-ad-1680614049921-bottom',
    mapping: {
      desktop: horizontalSplitViewDesktopSizes,
      tablet: horizontalSplitViewTabletSizes,
      mobile: horizontalSplitViewMobileSizes,
    },
  },
];

//
// Google Ads Slots Mapping for Club Profie page
//
export const clubProfileGoogleAdsSlots: Slot[] = [
  {
    name: 'chrono_club_profile_topbanner',
    path: `/22825980381/${TOP_LEVEL}/chrono_club_profile_topbanner`,
    id: 'div-gpt-ad-168061406245-top',
    mapping: {
      desktop: horizontalSplitViewDesktopSizes,
      tablet: horizontalSplitViewTabletSizes,
      mobile: horizontalSplitViewMobileSizes,
    },
  },
  {
    name: 'chrono_club_profile_bottombanner',
    path: `/22825980381/${TOP_LEVEL}/chrono_club_profile_bottombanner`,
    id: 'div-gpt-ad-1680614245014-bottom',
    mapping: {
      desktop: horizontalSplitViewDesktopSizes,
      tablet: horizontalSplitViewTabletSizes,
      mobile: horizontalSplitViewMobileSizes,
    },
  },
];

const isHostExcluded = () => {
  const googleAdsExcludedHosts = ['demo', 'preprod', 'members', 'trial', 'localhost'];
  return googleAdsExcludedHosts.some((host) => window.location.hostname.includes(host));
};

const isPathExcluded = () => {
  const googleAdsExcludedHosts = [
    'sandals-emerald-bay',
    'sandals-cap-estate',
    'sandals-upton-estate',
    'desert-winds-golf-course',
    'kaneohe-klipper-golf-course',
    'marine-memorial-golf-course',
    'marine-memorial-golf-course-california',
    'medal-of-honor-golf-course',
    'miramar-memorial-golf-course',
    'paradise-point-golf-club',
    'sound-of-freedom-golf-course',
    'taiyo-golf-club',
    'the-legends-at-parris-island',
    'kapalua',
  ];
  return googleAdsExcludedHosts.some((path) => window.location.pathname.includes(path));
};

export const allowAds = () => {
  return (
    process.env.ADS_ENABLED === 'true' &&
    typeof window !== 'undefined' &&
    isHostExcluded() === false &&
    isPathExcluded() === false
  );
};

export const initAds = (googleAdsSlots: Slot[]) => {
  if (typeof window === 'undefined' || process.env.ADS_ENABLED === 'false') return;

  window.googletag = window.googletag || { cmd: [] };
  const { googletag } = window;
  googletag.cmd.push(() => {
    googleAdsSlots?.forEach((s) => {
      // Ad Sizes Mapping Doc: https://developers.google.com/publisher-tag/guides/ad-sizes#responsive_ads
      const sizesMapping =
        googletag
          .sizeMapping()
          .addSize([1279, 768], s.mapping.desktop)
          .addSize([1023, 768], s.mapping.tablet)
          .addSize([599, 480], s.mapping.mobile)
          .addSize([0, 0], s.mapping.mobile)
          .build() || [];

      // The defineSlot() second parameters is set to [0, 0] because we use sizeMapping in any case here, and to avoid a warning about it being missing.
      // See https://developers.google.com/publisher-tag/reference#googletag.defineSlot
      googletag
        .defineSlot(s.path, [0, 0], s.id)
        ?.addService(googletag.pubads())
        .defineSizeMapping(sizesMapping);
    });

    googletag.pubads().enableLazyLoad({
      fetchMarginPercent: -1, // Fetch slots at load (because of our small pages length).
      renderMarginPercent: 1, // Render slots within 1 viewport for tablet and desktop.
      mobileScaling: 2.0, // Double the above values on mobile.
    });
    googletag.pubads().enableSingleRequest();
    googletag.pubads().collapseEmptyDivs();
    googletag.enableServices();
  });
};

export const getAdId = (slots: Slot[], name: string): Slot['id'] => {
  const slot = slots?.find((s: Slot) => s.name === name);
  return slot?.id;
};
