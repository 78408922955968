import { MouseEvent, KeyboardEvent, ReactElement, AriaAttributes } from 'react';

import { ChevronLeftIcon, ChevronRightIcon } from '@lightspeed/golf-b2c-design-system';

type SlideControlProps = AriaAttributes & {
  reference: string;
  active: boolean;
  index: number;
  collectionSize: number;
  onPress: () => void;
};

export const SlideControl = ({
  reference,
  active,
  index,
  collectionSize,
  onPress,
}: SlideControlProps) => {
  const onClick = (event: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
    event.preventDefault();
    onPress();
  };

  const onKeyDown = (event: KeyboardEvent<HTMLAnchorElement>) => {
    if (['Enter', 'Space'].includes(event.code)) {
      event.preventDefault();
      onPress();
    }
  };

  return (
    <a
      onClick={onClick}
      onKeyDown={onKeyDown}
      href={`#${reference}`}
      className={`block h-2 w-2 rounded-full border border-solid border-white bg-white ${
        active ? 'border-grey-400 bg-opacity-100' : 'bg-opacity-0'
      }`}
      role="tab"
      aria-selected={active ? 'true' : 'false'}
    >
      <span className="sr-only">{`Slide ${index} of ${collectionSize}`}</span>
    </a>
  );
};

type CarouselSlidesControlsProps = {
  children: ReactElement<SlideControlProps>[];
};

export const CarouselSlidesControls = ({ children }: CarouselSlidesControlsProps) => {
  return (
    <div
      className="absolute bottom-3 z-20 flex w-full justify-center gap-2"
      role="tablist"
      aria-label="Slides"
    >
      {children}
    </div>
  );
};

const sideControlsSharedClassName =
  'w-8 h-8 rounded-full absolute top-1/2 z-20 -mt-3 text-l text-black bg-white bg-opacity-70 lg:transition-opacity hover:bg-opacity-95 focus:bg-opacity-95 lg:opacity-0 lg:focus:opacity-95 lg:group-hover:opacity-100 active:outline active:outline-info';

type PreviousSlideControlProps = {
  onClick: () => void;
};

export const PreviousSlideControl = ({ onClick }: PreviousSlideControlProps) => {
  return (
    <button
      className={`left-4 ${sideControlsSharedClassName}`}
      type="button"
      aria-label="Display Previous Slide"
      onClick={onClick}
    >
      <ChevronLeftIcon className="m-auto" width={24} height={24} />
    </button>
  );
};

type NextSlideControlProps = {
  onClick: () => void;
};

export const NextSlideControl = ({ onClick }: NextSlideControlProps) => {
  return (
    <button
      className={`right-4 ${sideControlsSharedClassName}`}
      type="button"
      aria-label="Display Next Slide"
      onClick={onClick}
    >
      <ChevronRightIcon className="m-auto" width={24} height={24} />
    </button>
  );
};
