export function getCurrencySymbol(formatter: Intl.NumberFormat) {
  const currencyParts = formatter.formatToParts(1000); // fake amount
  return currencyParts?.find((e) => e.type === 'currency')?.value || '$'; // Default to USD if no currency symbol is found
}

export function useLocalizedCurrencyFormatter(currencyCode?: string, locale: string = 'en-US') {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  });
}
