import { Children, useRef, useState, useEffect, ReactElement, cloneElement } from 'react';

import {
  CarouselSlidesControls,
  NextSlideControl,
  PreviousSlideControl,
  SlideControl,
} from './CarouselControls';

type SlideProps = {
  id: string;
  reference: string;
  children: ReactElement;
  index?: number;
  total?: number;
  active?: boolean;
};

export const ImageSlide = ({ children, id, reference, index, total, active }: SlideProps) => {
  return (
    <div
      key={`container-${id}`}
      id={reference}
      className="relative aspect-16/9 w-full shrink-0 snap-start overflow-hidden"
      role="tabpanel"
      aria-label={`${index} of ${total}`}
      aria-hidden={!active}
    >
      {children}
    </div>
  );
};

type CarouselProps = {
  children: ReactElement<SlideProps>[];
  title: string;
  rounded?: boolean;
  dots?: boolean;
};

const ImageCarousel = ({ children, title, rounded = true, dots = true }: CarouselProps) => {
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const goToNextSlide = () => {
    setActiveSlide(activeSlide + 1);
  };

  const goToPreviousSlide = () => {
    setActiveSlide(activeSlide - 1);
  };

  const updateActiveSlideOnScroll = () => {
    if (timeout.current !== null) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      if (!sliderRef.current) return;
      const sliderWidth = sliderRef.current.getBoundingClientRect().width;
      if (sliderWidth === undefined) throw new Error('Error while computing slider width');
      const currentSlide = activeSlide;
      const slideForCurrentScroll = Math.round(sliderRef.current.scrollLeft / sliderWidth);
      if (currentSlide !== slideForCurrentScroll) setActiveSlide(slideForCurrentScroll);
    }, 100);
  };

  useEffect(() => {
    if (!sliderRef.current) return;
    const sliderWidth = sliderRef.current.getBoundingClientRect().width;
    if (sliderWidth === undefined) throw new Error('Error while computing slider width');
    const scrollDistance = sliderWidth * activeSlide;
    sliderRef.current.scroll({
      left: scrollDistance,
      behavior: 'smooth',
    });
  }, [sliderRef, activeSlide]);

  return (
    <div
      role="group"
      aria-roledescription="carousel"
      aria-label={title}
      className="relative w-full overflow-hidden"
    >
      {activeSlide !== 0 ? <PreviousSlideControl onClick={goToPreviousSlide} /> : null}
      {dots && (
        <CarouselSlidesControls>
          {Children.map(children, (slide, index) => (
            <SlideControl
              active={index === activeSlide}
              collectionSize={children.length}
              index={index}
              key={slide.props.reference}
              reference={slide.props.reference}
              onPress={() => setActiveSlide(index)}
            />
          ))}
        </CarouselSlidesControls>
      )}
      {activeSlide + 1 !== children.length ? <NextSlideControl onClick={goToNextSlide} /> : null}
      <div
        ref={sliderRef}
        className={`relative z-10 flex snap-x snap-mandatory overflow-hidden overflow-x-auto overscroll-x-contain scroll-auto ${
          rounded ? 'rounded-md' : ''
        } hide-scrollbar`}
        onScroll={updateActiveSlideOnScroll}
        role="tablist"
        aria-atomic="false"
        aria-live="polite"
      >
        {Children.map(children, (slide, index) =>
          cloneElement(slide, { index, total: children.length, active: index === activeSlide })
        )}
      </div>
    </div>
  );
};

export default ImageCarousel;
