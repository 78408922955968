import { buildAlternateUrl } from './urls';

type seoPagesMetas = {
  siteName: string;
  title: string;
  description: string;
  canonical: string;
  image: string;
  pageUrl: string;
  robots?: string;
};

export const getPagesMetas = ({
  siteName,
  title,
  description,
  canonical,
  image,
  pageUrl,
  robots = 'index, follow', // Fallback to index, follow if nothing different is submitted.
}: seoPagesMetas) => {
  const formattedCanonical = new URL(canonical);
  const formattedCanonicalString = formattedCanonical.origin + formattedCanonical.pathname;

  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link href={formattedCanonicalString} rel="canonical" />
      {/* <!-- Open Graph --> */}
      <meta key="og:title" property="og:title" content={title} />
      <meta key="og:site_name" property="og:site_name" content={siteName} />
      <meta key="og:description" property="og:description" content={description} />
      <meta key="og:type" property="og:type" content="website" />
      <meta key="og:url" property="og:url" content={canonical} />
      <meta key="og:image" property="og:image" content={image} />

      {/* <!-- Alternate --> */}
      <link href={buildAlternateUrl(pageUrl, 'com')} rel="alternate" hrefLang="en-US" />
      <link href={buildAlternateUrl(pageUrl, 'ca')} rel="alternate" hrefLang="en-CA" />
      <link href={buildAlternateUrl(pageUrl, 'ca', 'fr')} rel="alternate" hrefLang="fr-CA" />
      <link href={buildAlternateUrl(pageUrl, 'fr')} rel="alternate" hrefLang="fr-FR" />
      <link href={buildAlternateUrl(pageUrl, 'ie')} rel="alternate" hrefLang="en-IE" />
      <link href={buildAlternateUrl(pageUrl, 'de')} rel="alternate" hrefLang="de-DE" />
      <link href={buildAlternateUrl(pageUrl, 'co.nl')} rel="alternate" hrefLang="nl-NL" />
      <link href={buildAlternateUrl(pageUrl, 'es')} rel="alternate" hrefLang="es-ES" />
      <link href={buildAlternateUrl(pageUrl, 'it')} rel="alternate" hrefLang="it-IT" />
      <link href={buildAlternateUrl(pageUrl, 'ma')} rel="alternate" hrefLang="en-MA" />
      <link href={buildAlternateUrl(pageUrl, 'ma', 'fr')} rel="alternate" hrefLang="fr-MA" />

      {/* <!-- Robots --> */}
      <meta
        key="robots"
        name="robots"
        content={process.env.ROBOTS_INDEX === 'true' ? robots : 'noindex, nofollow'}
      />
    </>
  );
};

const appleTouchIconsSizes = [
  '57x57',
  '72x72',
  '76x76',
  '114x114',
  '120x120',
  '144x144',
  '152x152',
  '180x180',
];

export const getAppleTouchIcon = () => {
  return (
    <>
      <link rel="apple-touch-icon" href="/assets/images/ios-icons/apple-touch-icon.png" />
      {appleTouchIconsSizes.map((size) => (
        <link
          key={size}
          rel="apple-touch-icon"
          sizes={size}
          href={`/assets/images/ios-icons/apple-touch-icon-${size}.png`}
        />
      ))}
    </>
  );
};
