export function trackGa4Event(name: string, params?: object) {
  if (typeof window === 'undefined') return;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: name,
    ...params,
  });
}

// Expect the duration to be the return value of the process.hrtime method
// which is an array of two numbers, the first one being in seconds
// and the second in nanoseconds
export function convertExecTimeToServerTiming({
  task,
  duration,
}: {
  task: string;
  duration: [number, number];
}) {
  const ms = duration[1] / 1000000;
  return `${task};dur=${duration[0]}.${ms.toFixed(0)}`;
}
